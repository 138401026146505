<template>
  <div id="container">
    <icon-bar></icon-bar>
    <nav-bar :relativeLinks="relativeLinks"></nav-bar>
    <slot></slot>
  </div>
</template>
<script>
import IconBar from "@/components/IconBar.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "HeaderSection",
  components: {
    IconBar,
    NavBar,
  },
  props: {
    relativeLinks: Boolean,
  },
};
</script>
<style scoped>
#container {
  background-image: url("@/assets/background-long.jpg");
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  position: relative;
  padding-top: 28px;
}
</style>
