<template>
  <div>
    <div id="social-links" :style="{ color: dark ? '#2d2d2d' : 'white' }">
      <!-- social media links -->
      <a
        href="https://open.spotify.com/artist/38SKxCyfrmNWqWunb9wGHP?si=bu5RP0EKQF2UqFGhoR3uag&nd=1&dlsi=49efdc2421e44d27"
        ><font-awesome-icon :icon="['fab', 'spotify']" class="icon"
      /></a>
      <a href="https://instagram.com/goodkidband"
        ><font-awesome-icon :icon="['fab', 'instagram']" class="icon"
      /></a>
      <a href="https://youtube.com/c/goodkidband"
        ><font-awesome-icon :icon="['fab', 'youtube']" class="icon"
      /></a>
      <a href="https://discord.gg/goodkid"
        ><font-awesome-icon :icon="['fab', 'discord']" class="icon"
      /></a>
      <a href="https://tiktok.com/@goodkidband"
        ><font-awesome-icon :icon="['fab', 'tiktok']" class="icon"
      /></a>
      <a href="https://patreon.com/goodkidband"
        ><font-awesome-icon :icon="['fab', 'patreon']" class="icon"
      /></a>
      <a href="https://x.com/goodkidband"
        ><font-awesome-icon :icon="['fab', 'x-twitter']" class="icon"
      /></a>
      <a href="https://soundcloud.com/goodkidband"
        ><font-awesome-icon :icon="['fab', 'soundcloud']" class="icon"
      /></a>
    </div>
  </div>
</template>
  
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpotify,
  faInstagram,
  faYoutube,
  faTiktok,
  faPatreon,
  faXTwitter,
  faSoundcloud,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
library.add(
  faSpotify,
  faInstagram,
  faYoutube,
  faTiktok,
  faPatreon,
  faXTwitter,
  faSoundcloud,
  faDiscord
);

export default {
  name: "IconBar",
  props: {
    dark: Boolean,
  },
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#social-links {
  display: flex;
  justify-content: space-evenly;
  max-width: 600px;
  margin: 0 auto;
}

.icon {
  height: 20px;
  display: inline-block;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  max-width: 53px;
}

.icon:hover {
  opacity: 0.7;
}
</style>
  