<template>
  <div id="footer" :class="{ layered }">
    <ContactCard id="contact" :skipTitle="!layered"></ContactCard>
    <IconBar :dark="true" id="icon-bar"></IconBar>
    <CopyrightCard id="copyright"></CopyrightCard>
  </div>
</template>

<script>
import ContactCard from "@/components/ContactCard.vue";
import IconBar from "@/components/IconBar.vue";
import CopyrightCard from "@/components/CopyrightCard.vue";

export default {
  components: {
    ContactCard,
    IconBar,
    CopyrightCard,
  },
  props: {
    layered: Boolean,
  },
};
</script>

<style scoped>
#icon-bar {
  margin-top: 40px;
}
#copyright {
  margin-top: 40px;
}
#footer {
  padding-bottom: 10px;
}

.layered {
  background: url("@/assets/background-long.jpg");
  background-position: 50% 50%;
  background-size: cover;
  filter: drop-shadow(0px -4px 4px rgb(185, 185, 185));
}

@media screen and (max-width: 800px) {
  .layered {
    background: radial-gradient(#a6ebea, #47a891);
  }
}
</style>
