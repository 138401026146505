<template>
  <ul id="nav-menu" v-if="relativeLinks">
    <li class="nav-link"><a v-smooth-scroll href="#music">Music</a></li>
    <li class="nav-link"><a v-smooth-scroll href="#tour">Tour</a></li>
    <li class="nav-link">
      <a href="/merch">Merch</a>
    </li>
    <li class="nav-link">
      <a href="/creators" target="_blank">Creators</a>
    </li>
    <li class="nav-link">
      <a href="https://goodkidofficial.com/game" target="_blank">Game</a>
    </li>
    <li class="nav-link"><a v-smooth-scroll href="#contact">Contact</a></li>
  </ul>
  <ul id="nav-menu" v-else>
    <li class="nav-link"><a href="/">Home</a></li>
    <li class="nav-link"><a href="/#tour">Tour</a></li>
    <li class="nav-link">
      <a href="/merch">Merch</a>
    </li>
    <li class="nav-link">
      <a href="/creators" target="_blank">Creators</a>
    </li>
    <li class="nav-link">
      <a href="https://goodkidofficial.com/game" target="_blank">Game</a>
    </li>
    <li class="nav-link"><a href="/#contact">Contact</a></li>
  </ul>
</template>
  <script>
export default {
  name: "NavBar",
  props: {
    relativeLinks: Boolean,
  },
};
</script>
<style scoped>
#nav-menu {
  list-style: none;
  padding-right: 40px;
  float: none;
  position: relative;
  margin-top: 25px;
}

.nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  margin: 0px 20px 10px 20px;
  text-align: left;
  color: white;
}

.nav-link a {
  padding-top: 6px;
  padding-bottom: 6px;
  text-transform: uppercase;
  font-size: 24px;
  text-align: right;
  font-weight: 700;
  text-shadow: 1px 2px #00000073;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
}

.nav-link a:hover {
  text-decoration: none;
  border-bottom: 4px solid;
}
</style>
  